
































import { Component, Prop } from 'vue-property-decorator'

import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'
import { StructureConfigurable } from '../../../../support/mixins'

import { FooterProps } from './Footer.contracts'
import { FOOTER_COMPONENT_CONFIG_MAP, FOOTER_COMPONENT_KEY, FooterConfig } from './Footer.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Footer>({
  name: 'Footer',
  created () {
    this.config = this.getComponentConfig(FOOTER_COMPONENT_KEY, { ...FOOTER_COMPONENT_CONFIG_MAP })
  }
})
export class Footer extends StructureConfigurable implements FooterProps {
  @Prop({ type: Object, required: false })
  public siteLogo?: FooterProps['siteLogo']

  @Prop({ type: Object, required: false })
  public siteAuthor?: FooterProps['siteAuthor']

  @Inject(SiteServiceType, false)
  protected readonly siteService?: ISiteService

  protected config!: FooterConfig

  public get shouldDisplaySiteAuthorInSimple (): boolean {
    return this.getConfigProperty('shouldDisplaySiteAuthorInSimple')
  }

  public get shouldDisplayCopyrightsInSimple (): boolean {
    return this.getConfigProperty('shouldDisplayCopyrightsInSimple')
  }

  public get hasSiteAuthor (): boolean {
    return typeof this.siteAuthor !== 'undefined'
  }

  public get hasSiteLogo (): boolean {
    return typeof this.siteLogo !== 'undefined'
  }

  public get currentYear (): string {
    return new Date().getFullYear().toString()
  }

  /**
   * Determines address of the active site.
   */
  public get siteAddress (): string {
    return this.siteService?.getActiveSiteAddress() || '/'
  }

  public get siteName (): string {
    return this.siteService?.getActiveSite().name ?? ''
  }
}

export default Footer
